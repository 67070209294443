import { useState, useEffect, useRef, type CSSProperties } from 'react';

export type BannerData = {
  id: string;
  sticky?: boolean;
  node: React.ReactNode;
};

export interface BannerDataWithProperties extends BannerData {
  height?: number;
}

export interface BannerProps extends BannerData {
  onPropertyChange?: (bannerData: BannerDataWithProperties) => any;
  stickyOffset?: number;
}

const Banner = (props: BannerProps) => {
  const { onPropertyChange, stickyOffset, ...bannerData } = props;

  const bannerRef = useRef<HTMLDivElement>(null);

  const STICKY_STYLES: CSSProperties = {
    position: 'sticky',
    top: `${stickyOffset ?? 0}px`,
    zIndex: 1000,
  };

  const renderStyles = () => {
    if (bannerData.sticky) return STICKY_STYLES;
    return {};
  };

  const [withProperties, setWithProperties] =
    useState<BannerDataWithProperties>(bannerData);

  const checkProperties = () => {
    const updatedProperties: BannerDataWithProperties = {
      ...bannerData,
      // ... Round down to avoid gaps between banners
      height: Math.floor(bannerRef.current?.offsetHeight ?? 0),
    };

    const oldValues = Object.values(withProperties);
    const newValues = Object.values(updatedProperties);

    const hasChanged =
      oldValues.length !== newValues.length ||
      oldValues.filter((val, index) => newValues[index] !== val).length > 0;

    hasChanged && setWithProperties(updatedProperties);
  };

  useEffect(() => {
    if (onPropertyChange !== undefined) {
      onPropertyChange(withProperties);
    }
  }, [withProperties]);

  useEffect(() => {
    let resizeObserver: ResizeObserver;

    if (onPropertyChange !== undefined && bannerRef.current) {
      resizeObserver = new ResizeObserver(() => {
        // ... Update height for banner due to child change
        checkProperties();
      });

      checkProperties();
      resizeObserver.observe(bannerRef.current);
    }

    return () => {
      checkProperties();
      resizeObserver?.disconnect();
    };
  }, [bannerData]);

  return (
    <div style={renderStyles()} ref={bannerRef}>
      {bannerData.node}
    </div>
  );
};

export default Banner;
