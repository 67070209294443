import styles from './Footer.module.scss';

import Button from '@/components/atoms/Button';
import Column from '@/components/atoms/Column';
import Container from '@/components/atoms/Container';
import DOMPurify from 'isomorphic-dompurify';
import Product from '@/components/molecules/Product';
import Row from '@/components/atoms/Row';
import Social from '@/components/molecules/Social';
import Typography from '@/components/atoms/Typography';
import typeMenus from '@/utilities/types/Menus';
import { SOCIAL } from '@/utilities/constants/Social';
import { WEBSITE } from '@/utilities/constants/Website';
import { useLocale } from '@/utilities/contexts/Locale';
import { useWebsite } from '@/utilities/contexts/Website';
import FooterCustomiseProps from '@/utilities/types/FooterCustomise';
import Menu from '@/utilities/helpers/Menu';

interface FooterProps {
  menus: typeMenus;
  customise: FooterCustomiseProps;
}

const Footer = (props: FooterProps) => {
  const { menus, customise } = props;

  const website: string = useWebsite();

  const locale: string = useLocale();

  const date: Date = new Date();

  return (
    <footer className={styles.root}>
      {menus.footer && customise.mainMenu && (
        <div className={styles.menu}>
          <Container>
            <Row xs={2}>
              {menus.footer.map((parent) => {
                if (!parent.parentId) {
                  return (
                    <Column key={parent.id} xs={12} sm={6} lg={3}>
                      <Typography
                        className={styles.title}
                        tag="p"
                        weight="bold"
                        xs={3}
                      >
                        {parent.label}
                      </Typography>
                      {parent.childItems.nodes[0]?.id && <Menu item={parent} />}
                    </Column>
                  );
                }
              })}
            </Row>
          </Container>
        </div>
      )}
      {WEBSITE[website].products && menus.footer && customise.mainMenu && (
        <div className={styles.products}>
          <Container>
            <Row justify="center" xs={1}>
              <Column xs={12} md={8} lg={4}>
                <Product logo={true} product="safetyculture" />
              </Column>
              <Column xs={12} md={8} lg={4}>
                <Product logo={true} product="sheqsy" />
              </Column>
            </Row>
          </Container>
        </div>
      )}
      <div className={styles.bar}>
        <Container>
          <Row align="middle" justify="between" xs={1}>
            <Column xs={12} md={9}>
              {customise.legalMenu && (
                <ul className={styles.legal}>
                  {menus.legal &&
                    menus.legal.map((legal) => (
                      <li key={legal.id}>
                        <Button
                          external={legal.target ? true : false}
                          href={legal.url}
                        >
                          {legal.label}
                        </Button>
                      </li>
                    ))}
                  <li>
                    &copy; {WEBSITE[website].title} {date.getFullYear()}
                  </li>
                  {WEBSITE[website].copyright[locale] && (
                    <li
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(
                          WEBSITE[website].copyright[locale],
                        ),
                      }}
                    />
                  )}
                </ul>
              )}
            </Column>
            <Column>
              <Social
                facebook={SOCIAL.facebook}
                instagram={SOCIAL.instagram}
                linkedin={SOCIAL.linkedin}
                twitter={SOCIAL.twitter}
              />
            </Column>
          </Row>
        </Container>
      </div>
    </footer>
  );
};

export default Footer;
