import { IconProps } from '@/utilities/types/Icon';

const ArrowRight = (props: IconProps) => {
  const { height = 16, width = 16 } = props;

  return (
    <svg
      clipRule="evenodd"
      fill="none"
      fillRule="evenodd"
      height={height}
      viewBox="0 0 13 12"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="currentColor"
        d="M7.638.861a.667.667 0 1 0-.943.943l3.529 3.529H1.167a.667.667 0 0 0 0 1.333h9.057l-3.529 3.529a.667.667 0 1 0 .943.942l4.667-4.666a.667.667 0 0 0 0-.943L7.638.86Z"
      />
    </svg>
  );
};

export default ArrowRight;
