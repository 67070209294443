import styles from './Social.module.scss';

import Button from '@/components/atoms/Button';
import Image from 'next/image';
import cn from 'classnames';

interface SocialProps {
  className?: string;
  facebook?: string;
  height?: number;
  instagram?: string;
  linkedin?: string;
  twitter?: string;
  width?: number;
}

const Social = (props: SocialProps) => {
  const {
    className,
    facebook,
    height = 24,
    instagram,
    linkedin,
    twitter,
    width = 24,
  } = props;

  return (
    <ul className={cn(className, styles.root)}>
      {twitter && (
        <li>
          <Button external={true} href={twitter}>
            <Image
              alt="Twitter"
              height={height}
              src="/social/twitter.svg"
              width={width}
            />
          </Button>
        </li>
      )}
      {linkedin && (
        <li>
          <Button external={true} href={linkedin}>
            <Image
              alt="LinkedIn"
              height={height}
              src="/social/linkedin.svg"
              width={width}
            />
          </Button>
        </li>
      )}
      {facebook && (
        <li>
          <Button external={true} href={facebook}>
            <Image
              alt="Facebook"
              height={height}
              src="/social/facebook.svg"
              width={width}
            />
          </Button>
        </li>
      )}
      {instagram && (
        <li>
          <Button external={true} href={instagram}>
            <Image
              alt="Instagram"
              height={height}
              src="/social/instagram.svg"
              width={width}
            />
          </Button>
        </li>
      )}
    </ul>
  );
};

export default Social;
