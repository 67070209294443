import { IconProps } from '@/utilities/types/Icon';

const Signal = (props: IconProps) => {
  const { height = 16, width = 16 } = props;

  return (
    <svg
      clipRule="evenodd"
      fill="none"
      fillRule="evenodd"
      height={height}
      viewBox="0 0 24 24"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="currentColor"
        d="M5.636 4.222a1 1 0 0 1 0 1.414 9 9 0 0 0 0 12.728 1 1 0 0 1-1.414 1.414c-4.296-4.296-4.296-11.26 0-15.556a1 1 0 0 1 1.414 0Z"
      />
      <path
        fill="currentColor"
        d="M8.464 8.464A1 1 0 1 0 7.049 7.05a7 7 0 0 0 0 9.9 1 1 0 0 0 1.415-1.415 5 5 0 0 1 0-7.07ZM16.948 7.05a1 1 0 1 0-1.414 1.414 5 5 0 0 1 0 7.071 1 1 0 0 0 1.414 1.415 7 7 0 0 0 0-9.9Z"
      />
      <path
        fill="currentColor"
        d="M19.777 4.222a1 1 0 0 0-1.415 1.414 9 9 0 0 1 0 12.728 1 1 0 0 0 1.415 1.414c4.295-4.295 4.295-11.26 0-15.556ZM12 14a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"
      />
    </svg>
  );
};

export default Signal;
