import { IconProps } from '@/utilities/types/Icon';

const German = (props: IconProps) => {
  const { height = 24, width = 24 } = props;

  return (
    <svg
      clipRule="evenodd"
      fill="none"
      fillRule="evenodd"
      height={height}
      viewBox="0 0 16 16"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.0684 11.75C15.6631 10.6315 16 9.35509 16 8C16 6.64491 15.6631 5.36851 15.0684 4.25H0.931552C0.336917 5.36851 0 6.64491 0 8C0 9.35509 0.336917 10.6315 0.931552 11.75H15.0684Z"
        fill="#D80027"
      />
      <path
        d="M15.514 10.75C14.3926 13.8136 11.4512 16 7.99918 16C4.54715 16 1.60579 13.8136 0.484375 10.75H15.514Z"
        fill="#FFDA44"
      />
      <path
        d="M15.514 5.25H0.484375C1.60579 2.18643 4.54715 0 7.99918 0C11.4512 0 14.3926 2.18643 15.514 5.25Z"
        fill="#333333"
      />
    </svg>
  );
};

export default German;
