import { IconProps } from '@/utilities/types/Icon';

const Toggle = (props: IconProps) => {
  const { height = 32, width = 32 } = props;

  return (
    <svg
      clipRule="evenodd"
      fill="none"
      fillRule="evenodd"
      height={height}
      viewBox="0 0 100 100"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 29h60s14.499-.183 14.533 37.711c.01 11.27-3.567 14.96-9.274 14.958C79.552 81.668 75 74.999 75 74.999L25 25"
        fill="currentColor"
      />
      <path d="M20 50h60" fill="currentColor" />
      <path
        d="M20 71h60s14.499.183 14.533-37.711c.01-11.27-3.567-14.96-9.274-14.958-5.707.001-10.259 6.67-10.259 6.67L25 75"
        fill="currentColor"
      />
    </svg>
  );
};

export default Toggle;
