import styles from './Main.module.scss';

interface MainProps {
  children: React.ReactNode;
}

const Main = (props: MainProps) => {
  const { children } = props;

  return <main className={styles.root}>{children}</main>;
};

export default Main;
