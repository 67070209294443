export const PAGES: any = {
  page: {
    au: 'page',
    de: 'seite',
    en: 'page',
    es: 'pagina',
    fr: 'page',
    nl: 'pagina',
    'pt-br': 'pagina',
    us: 'page',
  },
};
