import styles from './MobileToggle.module.scss';

import cn from 'classnames';
import Toggle from '@/icons/custom/Toggle';
import { HEADER } from '@/utilities/constants/Header';
import { useLocale } from '@/utilities/contexts/Locale';
import Button from '@/components/atoms/Button';

interface MobileToggleProps {
  isMobile: boolean;
  onClick?: (e: any) => void;
}

const MobileToggle = (props: MobileToggleProps) => {
  const { isMobile, onClick } = props;

  const locale = useLocale();

  return (
    <div className={cn(isMobile && styles['is-active'], styles.toggle)}>
      <Button
        aria={HEADER.menu.button[locale]}
        icon={true}
        onClick={onClick}
        size="medium"
        style="tertiary"
        type="button"
      >
        <Toggle />
      </Button>
    </div>
  );
};

export default MobileToggle;
