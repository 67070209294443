import { IconProps } from '@/utilities/types/Icon';

const ChevronLeft = (props: IconProps) => {
  const { height = 24, width = 24 } = props;

  return (
    <svg
      clipRule="evenodd"
      fill="none"
      fillRule="evenodd"
      height={height}
      viewBox="0 0 24 24"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.7071 3.29289C16.0976 3.68342 16.0976 4.31658 15.7071 4.70711L8.41421 12L15.7071 19.2929C16.0976 19.6834 16.0976 20.3166 15.7071 20.7071C15.3166 21.0976 14.6834 21.0976 14.2929 20.7071L6.29289 12.7071C5.90237 12.3166 5.90237 11.6834 6.29289 11.2929L14.2929 3.29289C14.6834 2.90237 15.3166 2.90237 15.7071 3.29289Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ChevronLeft;
