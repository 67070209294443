import typeProduct from '@/utilities/types/Product';
import { createContext, useContext } from 'react';

const ProductContext = createContext({} as typeProduct['override']);

interface ProductProps {
  children: React.ReactNode;
  product?: typeProduct['override'];
}

const Product = (props: ProductProps) => {
  const { children, product } = props;

  return (
    <ProductContext.Provider value={product as typeProduct['override']}>
      {children}
    </ProductContext.Provider>
  );
};

export default Product;

export const useProduct = () => useContext(ProductContext);
