import { IconProps } from '@/utilities/types/Icon';

const Training = (props: IconProps) => {
  const { height = 16, width = 16 } = props;
  return (
    <svg
      clipRule="evenodd"
      fill="none"
      fillRule="evenodd"
      height={height}
      viewBox="0 0 23 20"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.7234 0.70248C10.9062 0.668184 11.0938 0.668184 11.2766 0.70248C11.493 0.743087 11.6861 0.841616 11.7794 0.889226L11.805 0.902199L21.4472 5.72331C21.4499 5.72465 21.4526 5.726 21.4552 5.72736L22.0052 6.00235L22.0366 6.01778C22.1506 6.07339 22.3871 6.18873 22.5718 6.37733C22.7274 6.53618 22.8457 6.72762 22.9182 6.93781C23.0043 7.18737 23.0017 7.45048 23.0005 7.57734L23.0002 7.61232V11.5321C23.0002 12.0844 22.5525 12.5321 22.0002 12.5321C21.4479 12.5321 21.0002 12.0844 21.0002 11.5321V7.73593L19 8.73576L19.0008 15.1185C19.0009 15.6179 18.7854 16.093 18.4097 16.4219C14.1683 20.1349 7.83341 20.1348 3.59202 16.4219C3.21639 16.093 3.00091 15.6181 3.00085 15.1189L3.00003 8.73579L0.552786 7.51217C0.214002 7.34278 0 6.99651 0 6.61774C0 6.23897 0.214002 5.89271 0.552786 5.72331L10.195 0.902199L10.2206 0.889226C10.3139 0.841617 10.507 0.743087 10.7234 0.70248ZM18.7639 6.61776L16.553 5.51232C16.5503 5.51098 16.5476 5.50962 16.545 5.50826L11 2.73577L3.23607 6.61774L11 10.4997L18.7639 6.61776ZM11.805 12.3333L17.0002 9.73569L17.0008 14.9961C13.5451 17.9435 8.45654 17.9434 5.00083 14.996L5.00016 9.73585L10.195 12.3333L10.2206 12.3463C10.3139 12.3939 10.507 12.4924 10.7234 12.533C10.9062 12.5673 11.0938 12.5673 11.2766 12.533C11.4931 12.4924 11.6862 12.3938 11.7795 12.3462L11.805 12.3333Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Training;
