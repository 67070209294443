import styles from './Column.module.scss';

import cn from 'classnames';
import { MutableRefObject } from 'react';

interface ColumnProps {
  children: React.ReactNode;
  className?: string;
  reference?: MutableRefObject<HTMLDivElement | null>;
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
}

const Column = (props: ColumnProps) => {
  const { children, className, reference, xs, sm, md, lg, xl } = props;

  return (
    <div
      className={cn(
        className,
        styles.root,
        styles[`xs-${xs}`],
        styles[`sm-${sm}`],
        styles[`md-${md}`],
        styles[`lg-${lg}`],
        styles[`xl-${xl}`],
      )}
      ref={reference}
    >
      {children}
    </div>
  );
};

export default Column;
