import { IconProps } from '@/utilities/types/Icon';

const Home = (props: IconProps) => {
  const { height = 24, width = 24 } = props;

  return (
    <svg
      clipRule="evenodd"
      fill="none"
      fillRule="evenodd"
      height={height}
      viewBox="0 0 24 24"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.635 1.37004C12.6408 0.723819 11.3592 0.72382 10.365 1.37005L2.36503 6.57004C1.51366 7.12344 1 8.06995 1 9.08538V20C1 21.6569 2.34315 23 4 23H20C21.6569 23 23 21.6569 23 20V9.08538C23 8.06995 22.4863 7.12344 21.635 6.57004L13.635 1.37004ZM11.455 3.04693C11.7864 2.83152 12.2136 2.83152 12.545 3.04693L20.545 8.24693C20.8288 8.4314 21 8.7469 21 9.08538V20C21 20.5523 20.5523 21 20 21H15.9998L16 13.4615C16 11.3739 14.0151 10 12.0001 10C9.98507 10 8.00017 11.3739 8.00016 13.4615L8 21L9.18182 21H4C3.44772 21 3 20.5523 3 20V9.08538C3 8.7469 3.17122 8.4314 3.45501 8.24693L11.455 3.04693ZM10 21H15.1817L13.9998 21L14 13.4615C14 12.8302 13.2987 12 12.0001 12C10.7015 12 10.0002 12.8302 10.0002 13.4615L10 21Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Home;
