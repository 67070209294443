import styles from './Pagination.module.scss';

import Button from '@/components/atoms/Button';
import ChevronLeft from '@/icons/maggie/ChevronLeft';
import ChevronRight from '@/icons/maggie/ChevronRight';
import Container from '@/components/atoms/Container';
import typePagination from '@/utilities/types/Pagination';
import { PAGES } from '@/utilities/constants/Pages';
import { PAGINATION } from '@/utilities/constants/Pagination';
import { useLocale } from '@/utilities/contexts/Locale';
import Head from 'next/head';

interface PaginationProps {
  pagination: typePagination;
}

const Pagination = (props: PaginationProps) => {
  const { pagination } = props;

  const locale: string = useLocale();

  const siblings: number = 3;

  const noindex = [
    pagination.current > 10,
    pagination.type.includes('authors/') && pagination.current > 1,
  ].includes(true);

  const nofollow = {
    last: [pagination.total > 10].includes(true),
    numbers: (page: number) => {
      return [page > 10].includes(true);
    },
    prev: [pagination.current - 1 > 10].includes(true),
    next: [pagination.current + 1 > 10].includes(true),
  };

  const first: React.ReactNode =
    pagination.current > siblings ? (
      <div className={styles.page}>
        <Button href={`/${pagination.type}/`}>1</Button>
        <span>...</span>
      </div>
    ) : undefined;

  const last: React.ReactNode =
    pagination.current <= pagination.total - siblings ? (
      <div className={styles.page}>
        <span>...</span>
        <Button
          href={`/${pagination.type}/${PAGES.page[locale]}/${pagination.total}/`}
          rel={nofollow.last ? 'nofollow' : undefined}
        >
          {pagination.total}
        </Button>
      </div>
    ) : undefined;

  const pages: number[] = [];

  for (let i = 1; i < pagination.total + 1; i++) {
    pages.push(i);
  }

  const numbers: React.ReactNode[] = pages.map((page: number) => {
    if (
      (page >= pagination.current && page < pagination.current + siblings) ||
      (page <= pagination.current && page > pagination.current - siblings)
    ) {
      return (
        <li key={page}>
          <Button
            href={`${
              page === 1
                ? `/${pagination.type}/`
                : `/${pagination.type}/${PAGES.page[locale]}/${page}/`
            }`}
            icon={pagination.current === page ? true : false}
            size={pagination.current === page ? 'medium' : undefined}
            style={pagination.current === page ? 'primary' : undefined}
            rel={nofollow.numbers(page) ? 'nofollow' : undefined}
          >
            {page}
          </Button>
        </li>
      );
    }
  });

  return numbers.length > 1 && pagination.current <= pagination.total ? (
    <>
      {noindex && (
        <Head>
          <meta name="robots" content="noindex" />
        </Head>
      )}
      <Container>
        <div className={styles.root}>
          {pagination.current > 1 && (
            <Button
              aria={PAGINATION.previous[locale]}
              href={`${
                pagination.current === 2
                  ? `/${pagination.type}/`
                  : `/${pagination.type}/${PAGES.page[locale]}/${
                      pagination.current - 1
                    }/`
              }`}
              icon={true}
              size="medium"
              style="secondary"
              rel={nofollow.prev ? 'nofollow' : undefined}
            >
              <ChevronLeft height={16} width={16} />
            </Button>
          )}
          <div className={styles.pages}>
            {first}
            <ol>{numbers}</ol>
            {last}
          </div>
          {pagination.current < pagination.total && (
            <Button
              aria={PAGINATION.next[locale]}
              href={`/${pagination.type}/${PAGES.page[locale]}/${
                pagination.current + 1
              }/`}
              icon={true}
              size="medium"
              style="secondary"
              rel={nofollow.next ? 'nofollow' : ''}
            >
              <ChevronRight height={16} width={16} />
            </Button>
          )}
        </div>
      </Container>
    </>
  ) : null;
};

export default Pagination;
