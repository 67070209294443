import { IconProps } from '@/utilities/types/Icon';

const Checklist = (props: IconProps) => {
  const { height = 16, width = 16 } = props;

  return (
    <svg
      clipRule="evenodd"
      fill="none"
      fillRule="evenodd"
      height={height}
      viewBox="0 0 20 23"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.7448 3H11.5851C11.4769 1.66655 10.3604 0.618164 8.99903 0.618164C7.63768 0.618164 6.52119 1.66655 6.41291 3H5.2552C4.61712 2.99999 4.07854 2.99997 3.63733 3.03602C3.17529 3.07377 2.73268 3.15599 2.31151 3.37058C1.67176 3.69655 1.15163 4.21668 0.825659 4.85643C0.611066 5.2776 0.528851 5.72021 0.4911 6.18225C0.455054 6.62345 0.455065 7.16198 0.455079 7.80004V18.1999C0.455065 18.8379 0.455054 19.3766 0.4911 19.8177C0.528851 20.2798 0.611066 20.7224 0.825659 21.1436C1.15163 21.7833 1.67176 22.3035 2.31151 22.6294C2.73268 22.844 3.17529 22.9262 3.63733 22.964C4.07852 23 4.61705 23 5.2551 23H11.0343C10.2256 22.4894 9.53738 21.8053 9.02182 21H5.29508C4.60652 21 4.15024 20.9992 3.8002 20.9706C3.46183 20.943 3.31172 20.8944 3.21949 20.8474C2.95607 20.7132 2.74189 20.499 2.60767 20.2356C2.56068 20.1434 2.5121 19.9932 2.48446 19.6549C2.45586 19.3048 2.45508 18.8486 2.45508 18.16V7.84C2.45508 7.15144 2.45586 6.69516 2.48446 6.34512C2.5121 6.00675 2.56068 5.85664 2.60767 5.76441C2.74189 5.50099 2.95607 5.28681 3.21949 5.15259C3.31172 5.1056 3.46183 5.05702 3.8002 5.02938C4.15024 5.00078 4.60652 5 5.29508 5H12.7049C13.3935 5 13.8498 5.00078 14.1998 5.02938C14.5382 5.05702 14.6883 5.1056 14.7805 5.15259C15.0439 5.28681 15.2581 5.50099 15.3923 5.76441C15.4393 5.85664 15.4879 6.00675 15.5156 6.34512C15.5441 6.69516 15.5449 7.15144 15.5449 7.84V11.0835C16.257 11.1986 16.9303 11.4293 17.5449 11.7558V7.8001C17.5449 7.16205 17.545 6.62344 17.5089 6.18225C17.4712 5.72021 17.3889 5.2776 17.1743 4.85643C16.8484 4.21668 16.3282 3.69655 15.6885 3.37058C15.2673 3.15599 14.8247 3.07377 14.3627 3.03602C13.9215 2.99997 13.3829 2.99999 12.7448 3Z"
        fill="currentColor"
      />
      <path
        d="M20 17.5C20 20.5376 17.5376 23 14.5 23C11.4624 23 9 20.5376 9 17.5C9 14.4624 11.4624 12 14.5 12C17.5376 12 20 14.4624 20 17.5ZM17.6066 15.0361C17.8501 15.2608 17.8653 15.6404 17.6405 15.8839L13.9031 19.9328C13.7895 20.0558 13.6297 20.1258 13.4622 20.1258C13.2948 20.1258 13.1349 20.0558 13.0214 19.9328L11.3603 18.1333C11.1355 17.8898 11.1507 17.5102 11.3942 17.2854C11.6377 17.0607 12.0173 17.0758 12.2421 17.3193L13.4622 18.6412L16.7587 15.07C16.9835 14.8265 17.3631 14.8113 17.6066 15.0361Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Checklist;
