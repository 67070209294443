export const PAGINATION: any = {
  next: {
    au: 'Next page',
    de: 'Nächste Seite',
    en: 'Next page',
    es: 'Página siguiente',
    fr: 'Page suivante',
    nl: 'Volgende pagina',
    'pt-br': 'Próxima página',
    us: 'Next page',
  },
  previous: {
    au: 'Previous page',
    de: 'Vorherige Seite',
    en: 'Previous page',
    es: 'Página anterior',
    fr: 'Page précédente',
    nl: 'Vorige pagina',
    'pt-br': 'Página anterior',
    us: 'Previous page',
  },
};
