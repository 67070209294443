import { useSegment } from '../contexts/segment/SegmentProvider';

const useBranchLink = () => {
  const segment = useSegment();

  const withAmpDev = (originalLink: string) => {
    if (segment?.anonymousId) {
      const { origin, pathname, searchParams } = new URL(originalLink);

      // Ensure all query params are preserved in the original link
      const newSearchParams = new URLSearchParams([
        ...Array.from(searchParams.entries()),
        ...[['amp_dev', segment.anonymousId]],
      ]);

      return `${origin}${pathname}?${newSearchParams.toString()}`;
    }
    return originalLink;
  };

  return {
    withAmpDev,
  };
};

export default useBranchLink;
