import DOMPurify from 'isomorphic-dompurify';
import Button from '@/components/atoms/Button';

interface typeMenuItem {
  id: string;
  label: string;
  parentId: string;
  target: boolean;
  url: string;
}

interface typeMenu {
  childItems: {
    nodes: typeMenuItem[];
  };
  id: string;
  label: string;
  parentId: string;
  target?: boolean;
  url?: string;
}

interface RenderMenuItemsProps {
  item: typeMenu;
}

const Menu = (props: RenderMenuItemsProps) => {
  const { item } = props;

  const renderSubMenu = (parent: typeMenu, level: number = 1) => {
    const filteredChildren = parent?.childItems?.nodes.filter(
      (child) => child.parentId === parent.id,
    );

    if (!filteredChildren || filteredChildren.length === 0) {
      return null;
    }

    return (
      <ul className={`menu-level-${level}`}>
        {filteredChildren.map((child) => (
          <li key={child.id}>
            <Button external={child.target} href={child.url}>
              <span
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(child.label),
                }}
              />
            </Button>
            {renderSubMenu(child as typeMenu, level + 1)}
          </li>
        ))}
      </ul>
    );
  };

  return (
    <ul>
      <li key={item.id}>{renderSubMenu(item)}</li>
    </ul>
  );
};

export default Menu;
