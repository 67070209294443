import styles from './Breadcrumbs.module.scss';

import Button from '@/components/atoms/Button';
import ChevronRight from '@/icons/maggie/ChevronRight';
import Container from '@/components/atoms/Container';
import Home from '@/icons/maggie/Home';
import Typography from '@/components/atoms/Typography';
import typeBreadcrumbs from '@/utilities/types/Breadcrumbs';
import { Fragment } from 'react';
import { WEBSITE } from '@/utilities/constants/Website';
import { useWebsite } from '@/utilities/contexts/Website';

interface BreadcrumbsProps {
  breadcrumbs?: typeBreadcrumbs[];
}

const Breadcrumbs = (props: BreadcrumbsProps) => {
  const { breadcrumbs } = props;

  const website: string = useWebsite();

  return (
    <div className={styles.root}>
      <Container>
        <ol
          className={styles.list}
          itemScope
          itemType="https://schema.org/BreadcrumbList"
        >
          <li
            itemProp="itemListElement"
            itemScope
            itemType="https://schema.org/ListItem"
          >
            <Button
              href="/"
              itemProp="item"
              aria={`${WEBSITE[website].title} Home`}
            >
              <span itemProp="name" hidden>
                {WEBSITE[website].title}
              </span>
              <Home height={12} width={12} />
            </Button>
            <meta itemProp="position" content="1" />
          </li>
          {breadcrumbs &&
            breadcrumbs.map((breadcrumb, index) => (
              <Fragment key={breadcrumb.title}>
                <li>
                  {breadcrumb.combine ? (
                    '&'
                  ) : (
                    <ChevronRight height={10} width={10} />
                  )}
                </li>
                <li
                  itemProp="itemListElement"
                  itemScope
                  itemType="https://schema.org/ListItem"
                >
                  {breadcrumb.href ? (
                    <Button href={breadcrumb.href} itemProp="item">
                      <span itemProp="name">{breadcrumb.title}</span>
                    </Button>
                  ) : (
                    <Typography xs={1}>
                      <span itemProp="name">{breadcrumb.title}</span>
                    </Typography>
                  )}
                  <meta itemProp="position" content={(index + 2).toString()} />
                </li>
              </Fragment>
            ))}
        </ol>
      </Container>
    </div>
  );
};

export default Breadcrumbs;
