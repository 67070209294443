import { IconProps } from '@/utilities/types/Icon';

const Asset = (props: IconProps) => {
  const { height = 16, width = 16 } = props;

  return (
    <svg
      clipRule="evenodd"
      fill="none"
      fillRule="evenodd"
      height={height}
      viewBox="0 0 20 22"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.4033 0.185046C10.1375 0.130375 9.86329 0.130375 9.59747 0.185046C9.2902 0.248242 9.01556 0.402069 8.79722 0.524362L8.7377 0.55761L1.3377 4.66872L1.27456 4.7036C1.04343 4.8309 0.752811 4.99097 0.529635 5.23315C0.336684 5.44253 0.190663 5.69069 0.101338 5.96105C-0.00197883 6.27375 -0.000770164 6.60553 0.00019114 6.8694L0.000368761 6.94153V15.0586L0.00019114 15.1308C-0.000770164 15.3946 -0.00197883 15.7264 0.101338 16.0391C0.190663 16.3095 0.336684 16.5576 0.529635 16.767C0.7528 17.0092 1.0434 17.1692 1.27452 17.2966L1.3377 17.3314L8.7377 21.4426L8.79721 21.4758C9.01555 21.5981 9.29019 21.7519 9.59747 21.8151C9.86329 21.8698 10.1375 21.8698 10.4033 21.8151C10.7106 21.7519 10.9852 21.5981 11.2035 21.4758L11.263 21.4426L18.663 17.3314L18.7262 17.2966C18.9573 17.1693 19.2479 17.0092 19.4711 16.767C19.6641 16.5576 19.8101 16.3095 19.8994 16.0391C20.0027 15.7264 20.0015 15.3946 20.0005 15.1308L20.0004 15.0586V6.94153L20.0005 6.8694C20.0015 6.60553 20.0027 6.27375 19.8994 5.96105C19.8101 5.69069 19.6641 5.44253 19.4711 5.23315C19.2479 4.99097 18.9573 4.8309 18.7262 4.7036L18.663 4.66872L11.263 0.557609L11.2035 0.524362C10.9852 0.402069 10.7105 0.248242 10.4033 0.185046ZM9.70898 2.30592C9.86053 2.22173 9.93788 2.1792 9.99559 2.15136L10.0004 2.14907L10.0051 2.15136C10.0629 2.1792 10.1402 2.22173 10.2918 2.30592L16.9408 5.99986L10.0002 9.85579L3.05971 5.99997L9.70898 2.30592ZM2.00037 7.69937V15.0586C2.00037 15.2416 2.00078 15.3353 2.00487 15.4031L2.00523 15.4088L2.01007 15.4119C2.06737 15.4484 2.14903 15.4943 2.30898 15.5831L9 19.3004V11.588L2.00037 7.69937ZM11 19.3008L17.6918 15.5831C17.8517 15.4943 17.9334 15.4484 17.9907 15.4119L17.9955 15.4088L17.9959 15.4031C18 15.3353 18.0004 15.2416 18.0004 15.0586V7.69916L11 11.5883V19.3008Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Asset;
