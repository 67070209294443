import styles from './Container.module.scss';

interface ContainerProps {
  children: React.ReactNode;
}

const Container = (props: ContainerProps) => {
  const { children } = props;

  return <div className={styles.root}>{children}</div>;
};

export default Container;
