import styles from './Layout.module.scss';

import Breadcrumbs from '@/components/molecules/Breadcrumbs';
import Footer from '@/components/organisms/Footer';
import Header from '@/components/organisms/Header';
import Main from '@/components/organisms/Main';
import Meta from '@/modules/Meta';
import Pagination from '@/components/molecules/Pagination';
import Product from '@/utilities/contexts/Product';
import Status from '@/components/molecules/Status';
import WEBSITE from '@/utilities/constants/Website';
import cn from 'classnames';
import dynamic from 'next/dynamic';
import typeBreadcrumbs from '@/utilities/types/Breadcrumbs';
import typeMenus from '@/utilities/types/Menus';
import typePagination from '@/utilities/types/Pagination';
import typeProduct from '@/utilities/types/Product';
import typeSeo from '@/utilities/types/Seo';
import typeStatus from '@/utilities/types/Status';
import typeStore from '@/utilities/types/Store';
import { useWebsite } from '@/utilities/contexts/Website';
import typeCustomiseLayout from '@/utilities/types/LayoutCustomise';
import typeNotificationBanner from '@/utilities/types/NotificationBanner';
import GTM from '@/components/tracking/GTM';
// import { type BannerData } from '@/components/organisms/Banners/Banner';
// import { useQuery } from '@apollo/client';
// import GetNotificationBannerQuery from '@/utilities/api/v2/graphql/queries/global/GetNotificationBannerQuery';
import { useState } from 'react';
// import NotificationBanner from '@/components/molecules/NotificationBanner';
// import { useNotificationBanner } from '@/utilities/contexts/NotificationBanner';
// import { useApolloClient } from '@apollo/client';

const Store = dynamic(() => import('@/components/molecules/Store'));

interface LayoutProps {
  breadcrumbs?: typeBreadcrumbs[];
  children: React.ReactNode;
  className?: string;
  menus: typeMenus;
  pagination?: typePagination;
  product?: typeProduct['override'];
  seo: typeSeo;
  status?: typeStatus['status'];
  store?: typeStore;
  customise?: typeCustomiseLayout;
  notification?: {
    notificationBanner: typeNotificationBanner;
  };
}

const Layout = (props: LayoutProps) => {
  const {
    breadcrumbs,
    children,
    className,
    menus,
    pagination,
    product,
    seo,
    status,
    store,
    customise,
  } = props;

  const website: string = useWebsite();

  const [banners, setBanners] = useState([
    { id: 'store', sticky: true, node: <Store store={store} /> },
  ]);

  // const notificationBannerCookie = useNotificationBanner();

  // const client = useApolloClient();

  // useEffect(() => {
  //   const loadNotificationBanner = async () => {
  //     const { data, loading, error } = (await client.query({
  //       query: GetNotificationBannerQuery,
  //       fetchPolicy: 'cache-first',
  //     })) as any;

  //     if (data) {
  //       setBanners((prev) => {
  //         if (
  //           prev.map((banner) => banner.id).indexOf('notificationBanner') < 0
  //         ) {
  //           return [
  //             ...prev,
  //             {
  //               id: 'notificationBanner',
  //               node: (
  //                 <NotificationBanner
  //                   content={
  //                     data?.acfOptionsNotificationBanner?.acfNotificationBanner
  //                       ?.notificationBanner
  //                   }
  //                 />
  //               ),
  //             } as (typeof banners)[number],
  //           ];
  //         }
  //         return prev;
  //       });
  //     }
  //   };

  //   if (!notificationBannerCookie) {
  //     loadNotificationBanner();
  //   }
  // }, [notificationBannerCookie]);

  return (
    <>
      {WEBSITE[website].gtm && <GTM.Noscript />}
      <Product product={product}>
        <div className={cn(className, styles.root)}>
          <Meta seo={seo} />
          <Header
            banners={banners}
            menus={{
              header: menus.header,
            }}
            customise={{
              languageToggle: customise?.header.languageToggle,
              notification: customise?.header.notification,
              navigationStatus: customise?.header.navigationStatus,
              loginAndSignUpButtonsStatus:
                customise?.header.loginAndSignUpButtonsStatus,
              additionalLogo: {
                logo: {
                  altText: customise?.header?.additionalLogo
                    ? customise?.header?.additionalLogo?.logo?.altText
                    : null,
                  guid: customise?.header?.additionalLogo
                    ? customise?.header.additionalLogo?.logo.guid
                    : null,
                },
              },
            }}
            translations={seo.translations?.filter(
              (translation) => !Object.values(translation).includes(null),
            )}
          />
          <Main>
            {breadcrumbs && customise?.breadcrumbsOn && (
              <Breadcrumbs breadcrumbs={breadcrumbs} />
            )}
            {children}
            {pagination && <Pagination pagination={pagination} />}
          </Main>
          <Footer
            menus={{
              footer: menus.footer,
              legal: menus.legal,
            }}
            customise={{
              mainMenu: customise?.footer.mainMenu,
              legalMenu: customise?.footer.legalMenu,
            }}
          />
          {status && <Status status={status} />}
        </div>
      </Product>
    </>
  );
};

export default Layout;
