import { Fragment } from 'react';
import { SOCIAL } from '@/utilities/constants/Social';
import { useDomain } from '@/utilities/contexts/Domain';
import { useLocale } from '@/utilities/contexts/Locale';
import { useRouter } from 'next/router';
import { useWebsite } from '@/utilities/contexts/Website';
import { WEBSITE } from '@/utilities/constants/Website';
import DOMPurify from 'isomorphic-dompurify';
import Head from 'next/head';
import Script from 'next/script';
import typeSeo from '@/utilities/types/Seo';
import { Environment } from '@/utilities/helpers/Environment';
import GTM from '@/components/tracking/GTM';
import ZoomInfo from '@/components/tracking/ZoomInfo';

interface MetaProps {
  seo: typeSeo;
}

const Meta = (props: MetaProps) => {
  const { seo } = props;

  const domain: string = useDomain();

  const website: string = useWebsite();

  const locale: string = useLocale();

  const { asPath } = useRouter();

  const isSandpit = Environment();

  return (
    <>
      <Head>
        <title>{seo.title || WEBSITE[website].title}</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />

        <meta name="theme-color" content="#6559ff" />

        <meta
          name="robots"
          content={`${
            isSandpit
              ? 'noindex, nofollow'
              : `${seo.metaRobotsNoindex || 'index'}, ${
                  seo.metaRobotsNofollow || 'follow'
                }, max-image-preview:large, max-snippet:-1, max-video-preview:-1`
          }`}
        />

        {seo.metaDesc && <meta name="description" content={seo.metaDesc} />}

        <meta property="og:type" content={seo.openGraph.type} />
        <meta
          property="og:locale"
          content={WEBSITE[website].locales.openGraph[locale]}
        />
        <meta property="og:site_name" content={WEBSITE[website].title} />
        <meta
          property="og:title"
          content={seo.title || WEBSITE[website].title}
        />
        <meta
          property="og:url"
          content={`${
            locale === WEBSITE[website].locales.default
              ? `${domain}${asPath}`
              : `${domain}/${locale}${asPath}`
          }`}
        />
        <meta
          property="og:image"
          content={
            seo.openGraph.image || `${domain}/open-graph/safetyculture.png`
          }
        />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />

        {seo.openGraph.type === 'article' && (
          <>
            <meta property="article:publisher" content={SOCIAL.facebook} />
            <meta
              property="article:published_time"
              content={seo.openGraph.date}
            />
            <meta
              property="article:modified_time"
              content={seo.openGraph.modified}
            />
          </>
        )}

        {seo.translations &&
          seo.translations.map(
            (translation, index) =>
              Object.values(translation)[0] !== null && (
                <Fragment key={index}>
                  <link
                    rel="alternate"
                    hrefLang={
                      WEBSITE[website].locales.hreflang[
                        Object.keys(translation)[0]
                      ]
                    }
                    href={`${domain}${Object.values(translation)[0]}`}
                  />
                  {Object.keys(translation)[0] ===
                  WEBSITE[website].locales.default ? (
                    <link
                      rel="alternate"
                      hrefLang="x-default"
                      href={`${domain}${Object.values(translation)[0]}`}
                    />
                  ) : undefined}
                </Fragment>
              ),
          )}

        <link
          rel="canonical"
          href={`${
            locale === WEBSITE[website].locales.default
              ? `${domain}${asPath}`
              : `${domain}/${locale}${asPath}`
          }`}
        />

        <link rel="icon" href="/favicon/favicon.ico" sizes="any" />
        <link rel="icon" href="/favicon/icon.svg" type="image/svg+xml" />
        <link rel="apple-touch-icon" href="/favicon/180.png" />
        <link rel="manifest" href="/manifest.webmanifest" />
      </Head>

      <Script
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(`
            {
              "@context": "https://schema.org",
              "@graph": [
                {
                  "@type": "Organization",
                  "name": "${WEBSITE[website].title}",
                  "url": "${domain}",
                  "logo": "${domain}/favicon/512.png",
                  "sameAs": [
                    "${SOCIAL.facebook}",
                    "${SOCIAL.github}",
                    "${SOCIAL.instagram}",
                    "${SOCIAL.linkedin}",
                    "${SOCIAL.twitter}",
                    "${SOCIAL.youtube} "
                  ]
                },
                {
                  "@type": "WebSite",
                  "url": "${domain}",
                  "inLanguage": "${WEBSITE[website].locales.openGraph[
                    locale as string
                  ].replace('_', '-')}"
                }
                ${
                  seo.openGraph.type === 'article:author'
                    ? `,{
                  "@context": "https://schema.org",
                  "@type": "ProfilePage",
                  "mainEntity": {
                    "@type": "Person",
                    "name": "${seo.author?.firstName} ${seo.author?.lastName}",
                    "description": "${seo.author?.description}",
                    "image": "${seo.author?.image}",
                  }
                }
                `
                    : ''
                }
                ${
                  seo.openGraph.type === 'article'
                    ? `
                  ,{
                    "@type": "Article",
                    "headline": "${seo.title}",
                    "author": {
                      "@type": "Person",
                      "name": "${seo.author?.firstName} ${seo.author?.lastName}"
                    },
                    "image": [
                      "${
                        seo.openGraph.image ||
                        `${domain}/open-graph/safetyculture.png`
                      }"
                    ],
                    "datePublished": "${seo.openGraph.date}",
                    "dateModified": "${seo.openGraph.modified}"
                  }
                `
                    : ``
                }
              ]
            }
          `),
        }}
        id="schema"
        type="application/ld+json"
      />

      {!!WEBSITE[website].gtm && <GTM />}
      <ZoomInfo />
    </>
  );
};

export default Meta;
