import styles from './Status.module.scss';

import typeStatus from '@/utilities/types/Status';

interface StatusProps {
  status: typeStatus['status'];
}

const Status = (props: StatusProps) => {
  const { status } = props;

  return status === 'draft' ? (
    <div className={styles.root}>
      <span className={styles.title}>{status}</span>
    </div>
  ) : null;
};

export default Status;
