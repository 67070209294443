import styles from './Button.module.scss';

import Link from 'next/link';
import cn from 'classnames';
import typeButton from '@/utilities/types/Button';

interface ButtonProps {
  after?: React.ReactNode;
  anchor?: boolean;
  aria?: string;
  before?: React.ReactNode;
  children: React.ReactNode;
  className?: string;
  external?: boolean;
  href?: string;
  icon?: boolean;
  itemProp?: string;
  locale?: string;
  onClick?: (event: any) => void;
  size?: 'small' | 'medium' | 'large';
  style?: typeButton['style'];
  type?: 'button' | 'reset' | 'submit';
  reference?: any;
  id?: string;
  dataAnchor?: string;
  dataAnchorValues?: {};
  disabled?: boolean;
  rel?: string;
  target?: '_blank' | '_self' | undefined;
  onBlur?: (e: React.FocusEvent<HTMLButtonElement, Element>) => void;
}

const Button = (props: ButtonProps) => {
  const {
    after,
    anchor,
    aria,
    before,
    children,
    className,
    external,
    href,
    icon,
    itemProp,
    locale,
    onClick,
    size,
    style,
    type,
    reference,
    id,
    dataAnchor,
    dataAnchorValues,
    disabled,
    rel,
    onBlur,
  } = props;

  const classNames: string = cn(
    className,
    icon && styles.icon,
    size && styles[size],
    style && styles[style],
    className === 'isActive' ? styles.isActive : '',
    styles.root,
  );

  if (href) {
    return anchor || external ? (
      <a
        aria-label={aria}
        className={style ? classNames : className}
        href={href}
        itemProp={itemProp}
        rel={external ? 'noopener noreferrer' : rel}
        target={external ? '_blank' : undefined}
        ref={reference}
        id={id}
        data-anchor={dataAnchor}
        data-anchor-values={JSON.stringify(dataAnchorValues)}
        onClick={onClick}
      >
        {before && <i className={styles.before}>{before}</i>}
        {children}
        {after && <i className={styles.after}>{after}</i>}
      </a>
    ) : (
      <Link
        aria-label={aria}
        className={style ? classNames : className}
        href={href}
        itemProp={itemProp}
        locale={locale}
        prefetch={false}
        ref={reference}
        id={id}
        data-anchor={dataAnchor}
        data-anchor-values={JSON.stringify(dataAnchorValues)}
        rel={rel}
        onClick={onClick}
      >
        {before && <i className={styles.before}>{before}</i>}
        {children}
        {after && <i className={styles.after}>{after}</i>}
      </Link>
    );
  } else {
    return (
      <button
        aria-label={aria}
        className={style ? classNames : className}
        itemProp={itemProp}
        onClick={onClick}
        type={type}
        ref={reference}
        id={id}
        data-anchor={dataAnchor}
        data-anchor-values={JSON.stringify(dataAnchorValues)}
        disabled={disabled}
        onBlur={(e) => {
          onBlur && onBlur(e);
        }}
      >
        {before && <i className={styles.before}>{before}</i>}
        {children}
        {after && <i className={styles.after}>{after}</i>}
      </button>
    );
  }
};

export default Button;
