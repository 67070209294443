import styles from './Row.module.scss';

import cn from 'classnames';

interface RowProps {
  align?: 'baseline' | 'bottom' | 'middle' | 'top' | 'stretch';
  children: React.ReactNode;
  justify?: 'around' | 'between' | 'center' | 'evenly' | 'left' | 'right';
  grow?: number;
  reverse?: boolean;
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
}

const Row = (props: RowProps) => {
  const { align, children, justify, grow, reverse, xs, sm, md, lg, xl } = props;

  return (
    <div
      className={cn(
        align && styles[align],
        justify && styles[justify],
        grow !== undefined && styles[`grow-${grow}`],
        reverse && styles.reverse,
        styles.root,
        styles[`xs-${xs}`],
        styles[`sm-${sm}`],
        styles[`md-${md}`],
        styles[`lg-${lg}`],
        styles[`xl-${xl}`],
      )}
    >
      {children}
    </div>
  );
};

export default Row;
