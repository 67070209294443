import Script from 'next/script';
import { useNonce } from '@/utilities/contexts/Nonce';

const ZoomInfo = () => {
  const nonce = useNonce();

  return (
    <Script
      id="zoominfo-websights"
      nonce={nonce}
      async
      dangerouslySetInnerHTML={{
        __html: `
        window[
          (function (_03c, _wv) {
            var _np = "";
            for (var _WT = 0; _WT < _03c.length; _WT++) {
              var _Tj = _03c[_WT].charCodeAt();
              _Tj -= _wv;
              _Tj += 61;
              _wv > 9;
              _Tj %= 94;
              _Tj += 33;
              _np == _np;
              _Tj != _WT;
              _np += String.fromCharCode(_Tj);
            }
            return _np;
          })(atob("b15lKSYhengrYHow"), 21)
        ] = "c320742d631683002306";
        var zi = document.createElement("script");
        var noncescript=document.querySelector('[nonce]');
            noncescript&&zi.setAttribute('nonce',noncescript.nonce||noncescript.getAttribute('nonce'));
        (zi.type = "text/javascript"),
          (zi.async = true),
          (zi.src = (function (_m8b, _MT) {
            var _Kq = "";
            for (var _un = 0; _un < _m8b.length; _un++) {
              var _hZ = _m8b[_un].charCodeAt();
              _hZ -= _MT;
              _hZ += 61;
              _hZ != _un;
              _hZ %= 94;
              _hZ += 33;
              _MT > 7;
              _Kq == _Kq;
              _Kq += String.fromCharCode(_hZ);
            }
            return _Kq;
          })(atob("dCIifCFGOzt2IToodTkhb351fCIhOm97eTsodTkibXM6diE="), 12)),
          document.readyState === "complete"
            ? document.body.appendChild(zi)
            : window.addEventListener("load", function () {
                document.body.appendChild(zi);
              });
        `,
      }}
    />
  );
};

export default ZoomInfo;
