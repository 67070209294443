import DOMPurify from 'isomorphic-dompurify';
import Script from 'next/script';

import { useNonce } from '@/utilities/contexts/Nonce';
import { useWebsite } from '@/utilities/contexts/Website';
import WEBSITE from '@/utilities/constants/Website';

const dataLayerSetup = `
  window.dataLayer = window.dataLayer || [];
  function gtag() {
    dataLayer.push(arguments)
  }
`;

const useGTM = () => {
  const website = useWebsite();
  const { production, testing } = WEBSITE[website].gtm;

  const isTestingEnvironment =
    process.env.NODE_ENV !== 'production' ||
    process.env.NEXT_PUBLIC_DOMAIN?.includes('sandpit');

  const CONTAINER_ID = (
    isTestingEnvironment ? testing ?? production : production
  ) as string;

  return CONTAINER_ID;
};

const GTM = () => {
  const nonce = useNonce();
  const website = useWebsite();
  const CONTAINER_ID = useGTM();

  return (
    <>
      <Script
        nonce={nonce}
        id="gtm_dataLayerSetup"
        data-cookieconsent="ignore"
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(dataLayerSetup),
        }}
      />
      <Script
        id="gtm_script"
        data-cookieconsent="ignore"
        nonce={nonce}
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(`
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;var n=d.querySelector('[nonce]');
            n&&j.setAttribute('nonce',n.nonce||n.getAttribute('nonce'));f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${CONTAINER_ID}');
          `),
        }}
      />
    </>
  );
};

const Noscript = () => {
  const CONTAINER_ID = useGTM();

  return (
    <noscript
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(
          `<iframe src="https://www.googletagmanager.com/ns.html?id=${CONTAINER_ID}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
          {
            ADD_TAGS: ['iframe'],
            ADD_ATTR: ['height', 'width', 'style'],
          },
        ),
      }}
    />
  );
};

GTM.Noscript = Noscript;
export default GTM;
