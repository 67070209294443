import styles from './Product.module.scss';

import { useProduct } from '@/utilities/contexts/Product';
import Button from '@/components/atoms/Button';
import Image from 'next/image';
import cn from 'classnames';
import typeAlignment from '@/utilities/types/Alignment';
import typeProduct from '@/utilities/types/Product';
import { PRODUCT } from '@/utilities/constants/Product';
import { useLocale } from '@/utilities/contexts/Locale';
import useBranchLink from '@/utilities/hooks/useBranchLink';

interface ProductProps {
  alignment?: typeAlignment['alignment'];
  className?: string;
  logo?: boolean;
  product: typeProduct['type'];
  branchLink?: string;
}

const Product = (props: ProductProps) => {
  const { alignment, className, logo, product, branchLink } = props;

  const locale: string = useLocale();

  const context: typeProduct['override'] = useProduct();

  const { withAmpDev } = useBranchLink();

  return (
    <ul
      className={cn(
        alignment && styles[alignment],
        className,
        logo ? styles.logo : styles.app,
        styles.root,
      )}
    >
      {logo && (
        <li>
          <Image
            alt={PRODUCT[product].title}
            height={PRODUCT[product].image.height}
            src={`/product/${product}.svg`}
            width={PRODUCT[product].image.width}
          />
        </li>
      )}
      <li>
        <Button
          external={true}
          dataAnchor="app-store"
          href={
            branchLink
              ? withAmpDev(branchLink)
              : context &&
                  context.override &&
                  context[product] &&
                  context[product]?.appStore
                ? withAmpDev(context[product]?.appStore as string)
                : withAmpDev(PRODUCT[product].appStore)
          }
        >
          <Image
            alt="App Store"
            height={logo ? 30 : 40}
            src={`/app/${locale}/app-store.svg`}
            width={logo ? 90 : 120}
          />
        </Button>
      </li>
      <li>
        <Button
          external={true}
          dataAnchor="play-store"
          href={
            branchLink
              ? withAmpDev(branchLink)
              : context &&
                  context.override &&
                  context[product] &&
                  context[product]?.googlePlay
                ? withAmpDev(context[product]?.googlePlay as string)
                : withAmpDev(PRODUCT[product].googlePlay)
          }
        >
          <Image
            alt="Google Play"
            height={logo ? 30 : 40}
            src={`/app/${locale}/google-play.svg`}
            width={logo ? 102 : 136}
          />
        </Button>
      </li>
    </ul>
  );
};

export default Product;
