import { useState, useEffect } from 'react';

import Banner, {
  BannerProps,
  type BannerData,
  type BannerDataWithProperties,
} from './Banner';

interface BannersProps {
  banners: BannerData[];
  onUpdate?: (bannerData: BannerDataWithProperties[]) => any;
}

const Banners = (props: BannersProps) => {
  const { banners, onUpdate } = props;

  const [bannerInformation, setBannerInformation] =
    useState<BannerDataWithProperties[]>(banners);

  const onUpdateBanner: BannerProps['onPropertyChange'] = (data) => {
    setBannerInformation(
      bannerInformation.map((banner) =>
        banner.id === data.id ? data : banner,
      ),
    );
  };

  const getStickyOffset = (bannerIndex: number) => {
    return bannerInformation.reduce((previous, current, index) => {
      if (index < bannerIndex && bannerInformation[index].sticky) {
        return previous + (current.height ?? 0);
      }
      return previous;
    }, 0);
  };

  useEffect(() => {}, []);

  // // ... Fire callback when data changes
  useEffect(() => {
    onUpdate !== undefined && onUpdate(bannerInformation);
  }, [bannerInformation]);

  return banners.map((banner, bannerIndex) => (
    <Banner
      key={banner.id}
      onPropertyChange={onUpdateBanner}
      stickyOffset={banner.sticky ? getStickyOffset(bannerIndex) : 0}
      {...banner}
    />
  ));
};

export default Banners;
