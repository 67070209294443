export const PRODUCT: any = {
  edapp: {
    appStore: 'https://apps.apple.com/au/app/edapp-mobile-lms/id991242619',
    googlePlay: 'https://play.google.com/store/apps/details?id=com.cld.ed',
    image: {
      height: 30,
      width: 69,
    },
    title: 'EdApp',
  },
  safetyculture: {
    appStore: 'http://sfty.io/q4Af/gyV5aog8Lx',
    googlePlay: 'http://sfty.io/q4Af/i9GC3loqMx',
    image: {
      height: 22,
      width: 140,
    },
    title: 'SafetyCulture',
  },
  sheqsy: {
    appStore: 'https://apps.apple.com/au/app/sheqsy/id1261798425',
    googlePlay: 'https://play.google.com/store/apps/details?id=com.sheqsy',
    image: {
      height: 30,
      width: 127,
    },
    title: 'SHEQSY',
  },
};
