import { IconProps } from '@/utilities/types/Icon';

const Puzzle = (props: IconProps) => {
  const { height = 16, width = 16 } = props;

  return (
    <svg
      clipRule="evenodd"
      fill="none"
      fillRule="evenodd"
      height={height}
      viewBox="0 0 23 23"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M11.129.793a1 1 0 0 1 1.414 0l2.62 2.62c.632-.985 1.441-1.606 2.362-1.84 1.267-.322 2.482.16 3.28.957.798.798 1.28 2.014.958 3.28-.234.921-.856 1.73-1.84 2.363l2.62 2.62a1 1 0 0 1 0 1.414l-3.6 3.6a1 1 0 0 1-1.642-.351c-.527-1.384-1.197-1.83-1.647-1.945-.468-.119-.984.044-1.374.433-.39.39-.552.906-.433 1.374.114.45.56 1.12 1.945 1.647a1 1 0 0 1 .35 1.642l-3.6 3.6a1 1 0 0 1-1.413 0l-2.62-2.62c-.632.985-1.442 1.606-2.363 1.84-1.266.322-2.482-.16-3.28-.958-.798-.798-1.279-2.013-.957-3.28.234-.92.855-1.73 1.84-2.363l-2.62-2.62a1 1 0 0 1 0-1.413l3.6-3.6a1 1 0 0 1 1.641.35c.528 1.385 1.198 1.831 1.648 1.945.468.12.984-.043 1.373-.433.39-.39.552-.905.433-1.373-.114-.45-.56-1.12-1.944-1.648a1 1 0 0 1-.351-1.641l3.6-3.6ZM9.923 4.827c.985.632 1.606 1.441 1.84 2.363.322 1.266-.16 2.481-.958 3.28-.798.797-2.013 1.279-3.28.957-.92-.234-1.73-.855-2.362-1.84L3.25 11.5l2.893 2.893a1 1 0 0 1-.351 1.641c-1.384.527-1.83 1.198-1.945 1.648-.119.468.044.984.433 1.373.39.39.906.552 1.374.433.45-.114 1.12-.56 1.647-1.944a1 1 0 0 1 1.642-.351l2.893 2.892 1.912-1.912c-.984-.632-1.605-1.442-1.84-2.363-.321-1.266.16-2.482.958-3.28.798-.798 2.014-1.279 3.28-.957.921.234 1.73.855 2.363 1.84l1.913-1.913-2.893-2.893a1 1 0 0 1 .35-1.642c1.385-.527 1.831-1.197 1.945-1.647.12-.468-.043-.984-.433-1.373-.39-.39-.905-.552-1.373-.434-.45.115-1.12.56-1.648 1.945a1 1 0 0 1-1.641.351l-2.893-2.893-1.913 1.913Z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default Puzzle;
