export const HEADER: any = {
  contact: {
    button: {
      href: {
        au: '/contact-us/',
        de: '/kontaktieren-sie-uns/',
        en: '/contact-us/',
        es: '/contactenos/',
        fr: '/contactez-nous/',
        nl: '/neem-contact-op/',
        'pt-br': '/entre-em-contato/',
        us: '/contact-us/',
      },
      title: {
        au: 'Talk to us today',
        de: 'Sprechen Sie noch heute mit uns.',
        en: 'Talk to us today',
        es: 'Hable con nosotros hoy',
        fr: "Contactez-nous dès aujourd'hui",
        nl: 'Praat vandaag nog met ons',
        'pt-br': 'Fale conosco hoje',
        us: 'Talk to us today',
      },
    },
    description: {
      au: "Questions? We're here to help",
      de: 'Fragen? Wir beraten Sie gerne.',
      en: "Questions? We're here to help",
      es: '¿Alguna pregunta? Estamos aquí para ayudar',
      fr: 'Des questions ? Nous sommes là pour vous aider',
      nl: 'Vragen? Wij zijn er om u te helpen',
      'pt-br': 'Perguntas? Estamos aqui para ajudar',
      us: "Questions? We're here to help",
    },
    title: {
      au: 'Contact us',
      de: 'Kontaktieren Sie uns',
      en: 'Contact us',
      es: 'Contáctenos',
      fr: 'Nous contacter',
      nl: 'Neem contact op',
      'pt-br': 'Entre em contato',
      us: 'Contact us',
    },
  },
  demo: {
    button: {
      href: {
        au: '/book-demo/',
        de: '/de/demo-buchen/',
        en: '/book-demo/',
        es: '/es/reservar-demostracion/',
        fr: '/fr/reserver-une-demonstration/',
        nl: '/nl/demonstratie-boeken/',
        'pt-br': '/pt-br/agendar-demonstracao/',
        us: '/book-demo/',
      },
      title: {
        au: 'Book a demo',
        de: 'Demo buchen',
        en: 'Book a demo',
        es: 'Reserve una demostración',
        fr: 'Réservez une démonstration',
        nl: 'Boek een demo',
        'pt-br': 'Agendar uma demonstração',
        us: 'Book a demo',
      },
    },
    description: {
      au: 'Learn more about SafetyCulture and how it can help your business. Trusted by over 1M business.',
      de: 'Erfahren Sie mehr über SafetyCulture und wie wir Ihrem Unternehmen helfen können. Mehr als eine Million Unternehmen vertrauen bereits auf uns.',
      en: 'Learn more about SafetyCulture and how it can help your business. Trusted by over 1M business.',
      es: 'Obtenga más información sobre SafetyCulture y cómo puede ayudar a su negocio. Con la confianza de más de 1 millón de empresas.',
      fr: "Découvrez comment SafetyCulture peut aider votre entreprise. Plus d'un million d'entreprises nous font déjà confiance.",
      nl: 'Meer informatie over SafetyCulture en hoe het uw bedrijf kan helpen. Vertrouwd door meer dan 1 miljoen bedrijven.',
      'pt-br':
        'Saiba mais sobre a SafetyCulture e como isso pode ajudar o seu negócio. Aprovado por mais de 1M de empresas.',
      us: 'Learn more about SafetyCulture and how it can help your business. Trusted by over 1M business.',
    },
    title: {
      au: 'Book a live demo',
      de: 'Live-Demo buchen',
      en: 'Book a live demo',
      es: 'Reserve una demostración en directo',
      fr: 'Réserver une démonstration en direct',
      nl: 'Boek uw live demonstratie',
      'pt-br': 'Agende a demonstração ao vivo',
      us: 'Book a live demo',
    },
  },
  menu: {
    button: {
      au: 'Mobile navigation',
      de: 'Mobiles Navigieren',
      en: 'Mobile navigation',
      es: 'Navegación móvil',
      fr: 'Navigation mobile',
      nl: 'Mobiele navigatie',
      'pt-br': 'Navegação móvel',
      us: 'Mobile navigation',
    },
  },
  support: {
    links: [
      {
        href: {
          au: '/getting-started-guide/',
          de: '/anleitung-fuer-die-ersten-schritte/',
          en: '/getting-started-guide/',
          es: '/guia-de-inicio/',
          fr: '/guide-de-demarrage/',
          nl: '/aan-de-slag-gids/',
          'pt-br': '/guia-de-introducao/',
          us: '/getting-started-guide/',
        },
        title: {
          au: 'Getting Started',
          de: 'Einstieg',
          en: 'Getting Started',
          es: 'Introducción',
          fr: 'Commencer',
          nl: 'Aan de slag',
          'pt-br': 'Começar a usar',
          us: 'Getting Started',
        },
      },
      {
        href: {
          au: 'https://support.safetyculture.com/product-updates/',
          de: 'https://support.safetyculture.com/product-updates/',
          en: 'https://support.safetyculture.com/product-updates/',
          es: 'https://support.safetyculture.com/product-updates/',
          fr: 'https://support.safetyculture.com/product-updates/',
          nl: 'https://support.safetyculture.com/product-updates/',
          'pt-br': 'https://support.safetyculture.com/product-updates/',
          us: 'https://support.safetyculture.com/product-updates/',
        },
        title: {
          au: 'Product Updates',
          de: 'Produkt-Updates',
          en: 'Product Updates',
          es: 'Actualizaciones del producto',
          fr: 'Mises à jour produit',
          nl: 'Product-updates',
          'pt-br': 'Atualizações de produtos',
          us: 'Product Updates',
        },
      },
      {
        href: {
          au: 'https://help.safetyculture.com/en-US/',
          de: 'https://help.safetyculture.com/de/',
          en: 'https://help.safetyculture.com/en-US/',
          es: 'https://help.safetyculture.com/es/',
          fr: 'https://help.safetyculture.com/fr/',
          nl: 'https://help.safetyculture.com/en-US/',
          'pt-br': 'https://help.safetyculture.com/en-US/',
          us: 'https://help.safetyculture.com/en-US/',
        },
        title: {
          au: 'Knowledge Base',
          de: 'Wissensdatenbank',
          en: 'Knowledge Base',
          es: 'Base de conocimientos',
          fr: 'Base de connaissances',
          nl: 'Product-updates',
          'pt-br': 'Base de conhecimento',
          us: 'Knowledge Base',
        },
      },
    ],
    title: {
      au: 'Support Centre',
      de: 'Support-Zentrum',
      en: 'Support Centre',
      es: 'Centro de soporte',
      fr: "Centre d'aide",
      nl: 'Supportcenter',
      'pt-br': 'Centro de suporte técnico',
      us: 'Support Centre',
    },
  },
  operations: {
    href: {
      au: '/platform/',
      de: '/de/plattform/',
      en: '/platform/',
      es: '/es/plataforma/',
      fr: '/fr/plateforme/',
      nl: '/nl/platform/',
      'pt-br': '/pt-br/plataforma/',
      us: '/platform/',
    },
    title: {
      au: 'Workplace Operations Platform',
      de: 'Plattform für Arbeitsabläufe',
      en: 'Workplace Operations Platform',
      es: 'Plataforma de Operaciones en el Lugar de Trabajo',
      fr: 'Plateforme des Opérations sur le Lieu de Travail',
      nl: 'Platform voor werkplekactiviteiten',
      'pt-br': 'Plataforma de Operações de Local de Trabalho',
      us: 'Workplace Operations Platform',
    },
    description: {
      au: 'Everything teams need to work safely, meet higher standards, and improve every day.',
      de: 'Alles, was Teams brauchen, um sicher zu arbeiten, höhere Standards zu erfüllen und sich täglich zu verbessern.',
      en: 'Everything teams need to work safely, meet higher standards, and improve every day.',
      es: 'Todo lo que los equipos necesitan para trabajar con seguridad, cumplir con los estándares más altos y mejorar cada día.',
      fr: "Tout ce dont vos équipes ont besoin pour travailler en toute sécurité, respecter des normes plus strictes et s'améliorer au quotidien.",
      nl: 'Alles wat teams nodig hebben om veilig te werken, aan hogere normen te voldoen en elke dag beter te worden.',
      'pt-br':
        'Tudo o que as equipes precisam para trabalhar com segurança, atender aos padrões mais elevados e melhorar a cada dia.',
      us: 'Everything teams need to work safely, meet higher standards, and improve every day.',
    },
    inspections: {
      href: {
        au: '/inspections-and-reports/',
        de: '/de/inspektionen-und-berichte/',
        en: '/inspections-and-reports/',
        es: '/es/inspecciones-e-informes/',
        fr: '/fr/inspections-et-rapports/',
        nl: '/nl/inspecties-en-rapporten/',
        'pt-br': '/pt-br/inspecoes-e-relatorios/',
        us: '/inspections-and-reports/',
      },
      title: {
        au: 'Inspections',
        de: 'Inspektionen',
        en: 'Inspections',
        es: 'Inspecciones',
        fr: 'Inspections',
        nl: 'Inspecties',
        'pt-br': 'Inspeções',
        us: 'Inspections',
      },
    },
    assets: {
      href: {
        au: '/asset-management/',
        de: '/de/assets/',
        en: '/asset-management/',
        es: '/es/activos/',
        fr: '/fr/actifs/',
        nl: '/nl/assets/',
        'pt-br': '/pt-br/recursos/',
        us: '/asset-management/',
      },
      title: {
        au: 'Assets',
        de: 'Assets',
        en: 'Assets',
        es: 'Activos',
        fr: 'Actifs',
        nl: 'Assets',
        'pt-br': 'Recursos',
        us: 'Assets',
      },
    },
    training: {
      href: {
        au: '/training/',
        de: '/training/',
        en: '/training/',
        es: '/training/',
        fr: '/training/',
        nl: '/training/',
        'pt-br': '/training/',
        us: '/training/',
      },
      title: {
        au: 'Training',
        de: 'Training',
        en: 'Training',
        es: 'Formación',
        fr: 'Formation',
        nl: 'Training',
        'pt-br': 'Formação',
        us: 'Training',
      },
    },
    integrations: {
      href: {
        au: '/integrations/',
        de: '/de/integrationen/',
        en: '/integrations/',
        es: '/es/integraciones/',
        fr: '/fr/integrations/',
        nl: '/nl/integraties/',
        'pt-br': '/pt-br/integracoes/',
        us: '/integrations/',
      },
      title: {
        au: 'Integrations',
        de: 'Integrationen',
        en: 'Integrations',
        es: 'Integraciones',
        fr: 'Intégrations',
        nl: 'Integraties',
        'pt-br': 'Integrações',
        us: 'Integrations',
      },
    },
    sensors: {
      href: {
        au: '/monitoring/',
        de: '/de/automatisierte-ueberwachung/',
        en: '/monitoring/',
        es: '/es/monitoreo-automatizado/',
        fr: '/fr/surveillance-automatisee/',
        nl: '/nl/geautomatiseerde-bewaking/',
        'pt-br': '/pt-br/monitoramento-automatizado/',
        us: '/monitoring/',
      },
      title: {
        au: 'Sensors & IoT',
        de: 'Sensoren und IoT',
        en: 'Sensors & IoT',
        es: 'Sensores e IoT',
        fr: 'Capteurs et IoT',
        nl: 'Sensoren en IoT',
        'pt-br': 'Sensores e IoT',
        us: 'Sensors & IoT',
      },
    },
    image: {
      alt: {
        au: 'Workplace Operations Platform',
        de: 'Plattform für Arbeitsabläufe',
        en: 'Workplace Operations Platform',
        es: 'Plata de Operaciones en el Lugar de Trabajo',
        fr: "Plateforme d'Opérations sur le Lieu de Travail",
        nl: 'Platform voor werkplekactiviteiten',
        'pt-br': 'Plataforma de Operações no Local de Trabalho',
        us: 'Workplace Operations Platform',
      },
    },
  },
};
