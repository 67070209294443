import { IconProps } from '@/utilities/types/Icon';

const ArrowOpenSquareOutright = (props: IconProps) => {
  const { height = 24, width = 24 } = props;

  return (
    <svg
      clipRule="evenodd"
      fill="none"
      fillRule="evenodd"
      height={height}
      viewBox="0 0 20 20"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="currentColor"
        d="M16.667 19.167a2.5 2.5 0 0 0 2.5-2.5V3.333a2.5 2.5 0 0 0-2.5-2.5H3.333a2.5 2.5 0 0 0-2.5 2.5v2.5a.833.833 0 0 0 1.667 0v-2.5c0-.46.373-.833.833-.833h13.334c.46 0 .833.373.833.833v13.334c0 .46-.373.833-.833.833H3.333a.833.833 0 0 1-.833-.833v-2.5a.833.833 0 0 0-1.667 0v2.5a2.5 2.5 0 0 0 2.5 2.5h13.334Z"
      />
      <path
        fill="currentColor"
        d="M10.244 13.923a.834.834 0 0 1 0-1.179l1.911-1.91H5.833a.833.833 0 1 1 0-1.667h6.322l-1.91-1.91a.833.833 0 0 1 1.178-1.18l3.333 3.334a.833.833 0 0 1 0 1.178l-3.333 3.334a.834.834 0 0 1-1.179 0Z"
      />
    </svg>
  );
};

export default ArrowOpenSquareOutright;
